
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component } from "vue-property-decorator";
import { mixin as clickaway } from "vue-clickaway";
import { mixins } from "vue-class-component";
// TODO: Fix import
import { userStore } from "@/store/modules/user";
import { IAddress } from "@/store/models/user";
import IStore from "@/store/models/store";
import { merchantStore } from "@/store/modules/merchant";
import MerchantInfo from "@/components/DataDisplay/MerchantInfo.vue";
import { getSimpleLoader } from "@/components/General/DynamicImages.vue";
import { keywordStore } from "@/store/modules/keyword";
import empty from "is-empty";
import cookies from "@/utils/cookies";

const google = window.google; // ok now
interface Position {
  lat: number;
  lng: number;
}
@Component({
  // metaInfo() {
  //   return {
  //     script: [
  //       {
  //         src: `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAP_KEY}&libraries=places`,
  //         async: true,
  //         defer: true // will declare it in methods
  //       }
  //     ]
  //   };
  // },
  components: {
    MerchantInfo
  }
})
export default class Home extends mixins(clickaway) {
  private service = null as any;
  private searchResults = [] as any[];
  private queryString = "";
  private isLoading: boolean = false;
  private searchKeyword = "";
  deliveryLocation = {} as any;
  private foodTagId = "";

  public MapsInit() {
    this.service = new google.maps.places.AutocompleteService();
  }

  async activated() {
    window.addEventListener("scroll", this.loadMoreButtonInView);

    const { id = "" } = this.$route.query as { id: string };
    this.foodTagId = id;

    if (
      this.$route.params.isReset ||
      (await cookies.getSessionStorage("isSearchBarReset")) == "YES"
    ) {
      cookies.removeSessionStorage("isSearchBarReset");
      if (empty(this.foodTagId)) {
        this.fetchKeywords();
        this.prepSearch();
      } else {
        this.searchStore("", true, "");
      }
    }
  }

  protected deactivated() {
    window.removeEventListener("scroll", this.loadMoreButtonInView);
  }

  prepSearch() {
    this.queryString = "";
    (this.$refs.search as HTMLElement).focus();
    merchantStore.COPY_TO_SEARCH_STORE();
    if (this.stores.length == 0) {
      this.searchStore("", false, "");
    }
  }

  get isEndOfList() {
    return (
      !this.isLoading &&
      !this.fetchingStores &&
      this.stores.length > 0 &&
      !this.searchStoresCursor
    );
  }

  get loader() {
    return getSimpleLoader().simpleLoader;
  }

  get loaderWebP() {
    return getSimpleLoader().simpleLoaderWebP;
  }

  get stores() {
    return merchantStore.searchStores;
  }

  get popularKeywords() {
    return keywordStore.keywords.map((e) => e.keyword);
  }

  get categoryName() {
    const { name = "" } = this.$route.query as { name: string };
    return name.toLowerCase();
  }

  get searchStoresCursor() {
    return merchantStore.searchStoresCursor;
  }

  get fetchingStores() {
    return merchantStore.fetchingStores;
  }

  get isLoadMoreStoresDisabled() {
    return this.fetchingStores || !this.searchStoresCursor;
  }

  loadMoreButtonInView() {
    const position = window.innerHeight + window.scrollY;
    const totalHeigh = document.documentElement?.scrollHeight;

    if (position < totalHeigh + 10 && position > totalHeigh - 10) {
      if (!this.fetchingStores && this.searchStoresCursor)
        this.searchStore(this.searchKeyword, true, this.searchStoresCursor);
    }
  }

  get bannerUrl() {
    return this.$route.query.bannerUrl;
  }

  onBannerClick(e) {
    e.preventDefault();
    const { redirectUrl = "" } = this.$route.query as { redirectUrl: string };
    if (redirectUrl && this.$route.query.bannerUrl) {
      window.open(redirectUrl, "_blank");
    }
  }

  protected clickStore(store: IStore) {
    this.$router.push(
      `/${store.id}/home?from=landing&customerID=${userStore.user?.customerID}`
    );
  }
  public async searchStore(
    input: string,
    isAppendSearch: boolean,
    cursor: string | number
  ) {
    if (empty(cursor)) {
      merchantStore.resetSearchStore();
    }
    this.searchKeyword = input;
    this.isLoading = true;
    try {
      this.deliveryLocation = JSON.parse(
        (await cookies.get("delivery:location")) || "{}"
      );

      if (
        !this.deliveryLocation.lat &&
        (await cookies.getSessionStorage("isAskedForAddress")) != "YES"
      ) {
        cookies.setSessionStorage("isAskedForAddress", "YES");
        setTimeout(() => {
          this.$ionic.toastController
            .create({
              header: "For better results, set up your location form home page",
              position: "bottom",
              color: "medium",
              duration: 5000
            })
            .then((x) => x.present());
        }, 6000);
      }
    } catch (e) {
      console.error(e);
    }
    // const { latitude, longitude } = await userStore.getCurrentPosition();
    const queryAddress = {
      // lat: latitude,
      // lng: longitude,
      lat: this.deliveryLocation.lat,
      lng: this.deliveryLocation.lng
    } as IAddress;
    const params = {
      searchInput: input,
      address: queryAddress,
      rcursor: cursor,
      isSearch: true,
      isAppendSearch: isAppendSearch,
      foodTagId: this.foodTagId
    };
    await merchantStore.getStores(params);

    this.isLoading = false;
  }

  public searchByKeyword(key: string) {
    this.queryString = key;
    this.searchStore(key, false, "");
  }

  public fetchKeywords() {
    keywordStore.fetchKeywords();
  }
}
