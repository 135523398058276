
import dayjs from "dayjs";
import { Picture } from "@/components";
import { Component, Vue, Prop } from "vue-property-decorator";
import IStore from "@/store/models/store";
import IFood from "@/store/models/food";
// import Cookies from "js-cookie";
import cookies from "@/utils/cookies";
import { cartStore } from "@/store/modules/cart";
import { merchantStore } from "@/store/modules/merchant";
import { menuStore } from "@/store/modules/menu";
import { uiStore } from "@/store/modules/ui";
@Component({
  components: {
    Picture
  }
})
export default class StoreFood extends Vue {
  @Prop() readonly store!: IStore;
  @Prop() readonly food!: IFood;

  async onFood(storeID, foodID) {
    cookies.set("currentStore", this.store.id);
    merchantStore.selectStore(null);
    menuStore.loadMenu([]);
    cartStore.clearCart();
    const cachedCart = JSON.parse(
      (await cookies.get(`cart-${this.store.id}`)) || "null"
    );
    if (cachedCart) {
      cartStore.loadCart(cachedCart);
    }
    this.$router.push(`/${storeID}/item/${foodID}`);
  }

  unavailableText() {
    const food = this.food;
    if (!food.isAvailable && food.nextAvailableSlot != "") {
      // Calculate the next available schedule, nearest to current hour
      const timeFormat = this.$i18n.locale == "cn" ? "A h:mm" : "h:mm A";
      const dayFormat =
        this.$i18n.locale == "cn" ? "M月D日, A h:mm" : "MMM D, h:mm A";
      const now = dayjs();
      const nextSlot = dayjs(food.nextAvailableSlot);

      if (nextSlot.isAfter(now)) {
        const format = now.isSame(nextSlot, "day") ? timeFormat : dayFormat;
        return this.$t("FoodItem.available-time", {
          time: nextSlot.format(format)
        });
      }

      return this.$t("FoodItem.unavailable");
    }
    return "";
  }
}
