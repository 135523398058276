import store from "@/store";
import IKeyword from "@/store/models/keyword";
import request from "@/utils/request";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

export interface IKeywordState {
  keywords: IKeyword[];
}

@Module({ store, dynamic: true, name: "keywordStore" })
class KeywordStore extends VuexModule implements IKeywordState {
  public keywords: IKeyword[] = [];

  @Mutation
  private UPDATE_KEYWORDS(payload: IKeyword[]) {
    this.keywords = payload;
  }

  @Action
  public async fetchKeywords() {
    try {
      const url = `v3/keywords`;
      const { items } = (await request.get(url)) as {
        items: IKeyword[];
      };
      this.UPDATE_KEYWORDS(items ? items : []);
      return items;
    } catch (e) {
      console.error(e);
    }
  }
}
export const keywordStore = getModule(KeywordStore);
