import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import IFood from "@/store/models/food";
import request from "@/utils/request";
import dayjs, { Dayjs } from "dayjs";
import { menuStore } from "./menu";

export interface IFoodState {}

@Module({ store, dynamic: true, name: "foodStore" })
class FoodStore extends VuexModule implements IFoodState {
  foodTags = [] as any;
  recommendations = [] as any;
  nestleProducts = [] as any;
  deliveryTime = [] as string[][];
  filteredDeliveryTime: Dayjs[] = [];

  @Mutation
  private SET_FOODTAGS(tags: any[]) {
    this.foodTags = tags;
  }

  @Mutation
  private SET_RECOMMENDATIONS(foods: any[]) {
    this.recommendations = menuStore.allFoods?.filter((food) =>
      foods.some((f) => f.id == food?.id)
    );
  }

  @Mutation
  private SET_NESTLE_PRODUCTS(foods: any[]) {
    this.nestleProducts = menuStore.allFoods?.filter((food) =>
      foods.some((f) => f.id == food?.id)
    );
  }

  @Mutation
  private SET_FOOD_DELIVERY_TIME(deliveryTime: string[][]) {
    this.deliveryTime = deliveryTime.filter((each) => each.length > 0) || [];
    this.filteredDeliveryTime = deliveryTime
      .map((each, index) => {
        return dayjs()
          .add(index, "day")
          .startOf("day");
      })
      .filter((time, i) => deliveryTime[i].length <= 0);
  }

  @Action
  public async setFoodTags(items) {
    this.SET_FOODTAGS(items);
  }

  @Action
  public async getFoodRecommendations(payload) {
    const { items } = (await request.get(
      `v3/web/store-food-recommendation?${new URLSearchParams({
        storeId: payload.storeId,
        isNestleFoodOnly: `${payload.isNestleFoodOnly}`
      })}`
    )) as {
      items: any[];
    };
    if (payload.isNestleFoodOnly) {
      this.SET_NESTLE_PRODUCTS(items);
    } else {
      this.SET_RECOMMENDATIONS(items);
    }
    return items;
  }

  @Action
  public async getFoodTags() {
    if (this.foodTags.length == 0) {
      const { items } = (await request.get(`v3/web/food/tags`)) as {
        items: any[];
      };
      console.log(items);
      this.SET_FOODTAGS(items);
    }
  }

  @Action
  public async fetchFood(foodId: string): Promise<IFood | null> {
    try {
      const response: any = await request.get(`v3/web/food/${foodId}`);
      const { item, deliveryTime } = response as {
        item: IFood;
        deliveryTime: string[][];
      };

      if (deliveryTime) {
        this.SET_FOOD_DELIVERY_TIME(deliveryTime);
      }
      return item;
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}
export const foodStore = getModule(FoodStore);
