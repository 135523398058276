<template>
  <div
    :aria-disabled="
      isStoreClosed ||
        (!isPreOrderAvailable && !isNowWithinBusinessHour && !isTodayOpen)
    "
    @click="clickStore"
    class="cursor-pointer flex flex-col items-start justify-start py-3 px-2 my-2"
  >
    <b
      class="flex items-center justify-start space-x-2 lg:text-l w-full text-left"
    >
      <span class="store-name break-words">{{ storeUnderMerchant.name }}</span>
      <div class="flex items-center" v-if="showStoreRating">
        <SvgIcon name="star" class="w-4 h-4 mr-0.5 mb-0.5" />
        <span class="text-gray-500 text-xs">{{ storeRating }}&nbsp;</span>
        <span class="text-gray-500 text-xs">({{ totalRatingCount }})</span>
      </div>
    </b>
    <div
      class="inline-flex items-center justify-between text-xs text-gray-500 pt-1"
    >
      <SvgIcon name="clock" class="inline-block align-middle w-4 h-4 mr-1" />
      <span v-if="isNowWithinBusinessHour && !isStoreClosed">Open</span>
      <span v-else-if="isPreOrderAvailable && !isStoreClosed">Pre order</span>
      <span v-else-if="isTodayOpen && !isStoreClosed">{{
        currentOpeningHour
      }}</span>
      <span v-else>Closed</span>
      <!-- <span class="mx-2">•</span>
                <span>{{ store.totalAcceptedOrders.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }} orders</span> -->
      <span class="mx-2">•</span>
      <span class="text-xs" v-if="+storeUnderMerchant.distance > 0">
        {{ formatDistance(storeUnderMerchant.distance) }}</span
      >
    </div>
    <div
      v-if="isFoodStore && Number(storeUnderMerchant.distance / 1000) > 20"
      class="flex flex-col items-start w-full text-xs py-1"
    >
      <p class="text-xs text-red-500 font-medium">
        {{ $t("too-far-to-deliver") }}
      </p>
      <p
        v-if="storeUnderMerchant.supportedDineType.includes('PICKUP')"
        class="text-xs text-gray-500 font-medium"
      >
        {{ $t("pickup-available") }}
      </p>
    </div>
    <div
      class="flex flex-row items-start w-full mt-1 mb-1 space-x-1 text-xs lg:justify-start text-center"
    >
      <span :key="di" v-for="(dt, di) in storeUnderMerchant.supportedDineType">
        <span
          v-if="dt === 'PICKUP'"
          class="px-1 text-blue-600 rounded-sm bg-blue-50"
          >{{ $t("StoreView.pickup") }}</span
        >
        <span
          v-else-if="dt === 'DELIVERY'"
          class="px-1 text-red-600 rounded-sm bg-red-50"
          >{{ $t("StoreView.delivery") }}</span
        >
        <span
          v-else-if="dt === 'DRIVE_THRU'"
          class="px-1 text-green-600 rounded-sm bg-green-50"
          >{{ $t("StoreView.drive-thru") }}</span
        >
        <span
          v-else-if="dt === 'DINEIN'"
          class="px-1 text-yellow-600 rounded-sm bg-yellow-50"
          >{{ $t("StoreView.walkin") }}</span
        >
      </span>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import cookies from "@/utils/cookies";
import { cartStore } from "@/store/modules/cart";
import { merchantStore } from "@/store/modules/merchant";
import { menuStore } from "@/store/modules/menu";
export default {
  props: ["storeUnderMerchant"],
  computed: {
    isFoodStore: function() {
      return merchantStore.isFoodStore(this.storeUnderMerchant);
    },
    now: function() {
      return dayjs();
    },
    yesterday: function() {
      return dayjs().subtract(1, "day");
    },
    isStoreClosed: function() {
      if (!this.storeUnderMerchant) return false;
      return dayjs(this.storeUnderMerchant.closedUntil)
        .utc()
        .isAfter(dayjs().utc(), "second");
    },
    isTodayOpen: function() {
      if (this.storeUnderMerchant) {
        return (
          this.storeUnderMerchant.currentOpeningHour != null &&
          this.storeUnderMerchant.currentClosingHour != null
        );
      }

      return false;
    },
    currentOpeningHour: function() {
      return this.isTodayOpen
        ? dayjs(this.storeUnderMerchant.currentOpeningHour).format("HH:mm")
        : "";
    },
    isNowWithinBusinessHour: function() {
      return this.storeUnderMerchant?.isOpen;
    },
    isPreOrderAvailable: function() {
      if (this.storeUnderMerchant) {
        return (
          this.storeUnderMerchant.orderAheadDays > 0 &&
          this.storeUnderMerchant.isDeliveryTimeAvailable
        );
      } else {
        return false;
      }
    },
    showStoreRating() {
      return this.storeUnderMerchant?.rating?.totalRatings >= 10;
    },
    storeRating() {
      const totalStars = this.storeUnderMerchant?.rating?.totalRatings * 5;
      const earnedStars = this.storeUnderMerchant?.rating?.stars;
      return ((earnedStars / totalStars) * 5).toFixed(2);
    },
    totalRatingCount() {
      return this.storeUnderMerchant?.rating?.totalRatings;
    }
    // isDisabled: function() {
    //   // let thirtyFiveMinsFromNow = dayjs().add(35, 'minute')
    //   // console.log("store", this.storeUnderMerchant.name, "thirtyFiveMinsFromNow", thirtyFiveMinsFromNow, "closedtill", dayjs(this.storeUnderMerchant.closedUntil), "diff", diff)
    //   if (this.storeUnderMerchant) {
    //     // if can preorder, dont disable, else, check other var
    //     if (this.isPreOrderAvailable) {
    //       return false;
    //     }

    //     // if merchant set to closed
    //     if (this.isStoreClosed) {
    //       // if closed for the day, disable
    //       if (
    //         dayjs(this.storeUnderMerchant.closedUntil)
    //           .utc()
    //           .isAfter(this.storeUnderMerchant.currentClosingHour, "second")
    //       ) {
    //         return true;
    //       }
    //       return false;
    //     }

    //     // else, check for business hours to determine if the store is closed or not
    //     if (this.isNowWithinBusinessHour) {
    //       if (this.isTodayOpen) {
    //         return false;
    //       }
    //       // just in case, if today is closed, disable
    //       return true;
    //     }

    //     // finally, not in business hour, disable
    //     return true;
    //   }
    //   return false;
    // }
  },
  methods: {
    formatDistance(distance) {
      if (+distance > 1000) {
        return `${(+distance / 1000).toFixed(1)} km`;
      } else {
        return `${+distance} m`;
      }
    },
    clickStore: async function(event) {
      merchantStore.selectStore(null);
      menuStore.loadMenu([]);
      cartStore.clearCart();
      //removed query since already been handled on app.vue
      const cachedCart = JSON.parse(
        (await cookies.get(`cart-${this.storeUnderMerchant.id}`)) || "null"
      );
      if (cachedCart) {
        cartStore.loadCart(cachedCart);
      }
      cookies.set("currentStore", this.storeUnderMerchant.id);
      this.$router.push({
        name: "StoreView",
        params: { id: this.storeUnderMerchant.id }
        // query: { from: "landing", customerID: userStore.user?.customerID || "" }
      });
    }
  }
};
</script>
<style lang="scss"></style>
